import React from 'react'
import {LayoutA} from '../../../layouts'

class PostVision extends React.Component {
  render() {
    return (
      <LayoutA>
        <div className="BlogPostTemplate">
          <h1 className="BlogPostTemplate-title">BLA</h1>
          <div className="BlogPostTemplate-content markdown-blog">
            <p>bla bla bla</p>
          </div>
        </div>
      </LayoutA>
    )
  }

}

export default PostVision;
